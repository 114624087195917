// Alerts

.alert-variant(@background; @border; @text-color) {
  background-color: @background;
  border-color: @border;
  color: @text-color;

  hr {
    border-top-color: @border;
  }
  .alert-link {
    color: @text-color;
  }
}
