//
// Pager pagination
// --------------------------------------------------


.pager {
  padding-left: 0;
  margin: @line-height-computed 0;
  list-style: none;
  text-align: center;
  &:extend(.clearfix all);
  li {
    display: inline;
    > a,
    > span {
      color: @pagination-color;
      display: inline-block;
      padding: @ts @rhs @bs @lhs;
      margin: 4px @rhs 8px @rhsNB;
      background-color: @pager-bg;
  border: @borderWidth solid @black;
  .box-shadow(0px 0px 0 4px @gray);
  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
    }

    > a:hover,
    > a:focus {
      text-decoration: none;
      background-color: @pager-hover-bg;
    }
  }

  .next {
    > a,
    > span {
      float: right;
    }
  }

  .previous {
    > a,
    > span {
      float: left;
    }
  }

  .disabled {
    > a,
    > a:hover,
    > a:focus,
    > span {
      color: @pager-disabled-color;
      background-color: @pager-bg;
      cursor: @cursor-disabled;
    }
  }
}
