//
// Tooltips
// --------------------------------------------------


// Base class
.tooltip {
  position: absolute;
  z-index: @zindex-tooltip;
  display: block;
  visibility: visible;
  // Reset font and text properties given new insertion method
  font-family: @font-family-base;
  font-size: @font-size-small;
  line-height: @baseLineHeight;
  .opacity(0);

  &.in     { }
  &.top    { margin-top:  0px; padding: @tooltip-arrow-width 0; }
  &.right  { margin-left: 0px; padding: 0 @tooltip-arrow-width; }
  &.bottom { margin-top:  0px; padding: @tooltip-arrow-width 0; }
  &.left   { margin-left: 0px; padding: 0 @tooltip-arrow-width; }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: @tooltip-max-width;
  padding: 0 @baseWidth;
  color: @tooltip-color;
  text-align: center;
  text-decoration: none;
  background-color: @tooltip-bg;
}

// Arrows
.tooltip-arrow {
  display: none;
}
// Note: Deprecated .top-left, .top-right, .bottom-left, and .bottom-right as of v3.3.1
