//
// Badges
// --------------------------------------------------


// Base class
.badge {
  display: inline-block;
  min-width: @baseWidth;
  padding: 0 @rhsNB 0 @lhsNB;
  font-size: @font-size-small;
  color: @badge-color;
  line-height: @badge-line-height;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: @badge-bg;

  // Empty badges collapse automatically (not available in IE8)
  &:empty {
    display: none;
  }

  // Quick fix for badges in buttons
  .btn & {
    position: relative;
  }
  
  .btn-xs & {
    top: 0;
  }

  // Hover state, but only for links
  a& {
    &:hover,
    &:focus {
      color: @badge-link-hover-color;
      text-decoration: none;
      cursor: pointer;
    }
  }

  // Account for badges in navs
  .list-group-item.active > &,
  .nav-pills > .active > a > & {
    color: @badge-active-color;
    background-color: @badge-active-bg;
  }
  
  .list-group-item > & {
    float: right;
  }
  
  .list-group-item > & + & {
    //margin-right: @rhsNB;
  }
  
  .nav-pills > li > a > & {
    //margin-left: @lhsNB;
  }
}
