//
// Wells
// --------------------------------------------------


// Base class
.well {
  min-height: 20px;
  color: @white;
  padding: @baseLineHeight @baseWidth;
  margin-bottom: @baseLineHeight;
  background-color: @well-bg;
  blockquote {
    border-color: #ddd;
    border-color: rgba(0,0,0,0);
  }
}

// Sizes
.well-lg {
  padding: (@ts * 2) (@rhs * 2) (@bs * 2) (@lhs * 2);
}
.well-sm {
  padding: @ts @rhs @bs @lhs;
}
