//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  padding: @jumbotron-padding (@jumbotron-padding);
  margin-bottom: @jumbotron-padding;
  color: @jumbotron-color;
  padding: @jumbotron-padding;
  margin: @ts @lhs @bs @rhs;
  background-color: @jumbotron-bg;
  border: @borderWidth solid @gray;

  h1,
  .h1 {
    margin: 0;
    color: @jumbotron-heading-color;
  }
  
  p {
    margin-bottom: 0;
  }

  > hr {
    border-top-color: @jumbotron-bg;
  }

  .container &,
  .container-fluid & {
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: @screen-sm-min) {
    padding: (@jumbotron-padding) 0;

    .container &,
    .container-fluid & {
      padding-left:  (@jumbotron-padding);
      padding-right: (@jumbotron-padding);
    }

    h1,
    .h1 {
      font-size: @font-size-base;
    }
  }
}
