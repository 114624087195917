//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  padding: 0 @baseLineWidth;
  .box-shadow(~"@{baseLineWidth} @{halfbaseLineHeight} 0 rgb(0,0,0)");
  cursor: default;
  border: 0px;
  .user-select(none);

  &::first-letter {
    color: @redDark;
  }

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      outline: 0;
    }
  }

  &:hover,
  &:focus,
  &.focus {
    outline: 0;
  }

  &:active,
  &.active {
    color: @gray !important;
    background: @black !important;
    outline: 0;
    &::first-letter {
      color: @gray !important;
    }
  }


  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    pointer-events: none; // Future-proof disabling of clicks
    .box-shadow(none);
  }
}
.navbar .btn {
  background: @grayDark;
  color: @white;
  .box-shadow(none);
  &::first-letter {
    color: @yellow;
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; @btn-default-border);
}
.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; @btn-primary-border);
}
// Success appears as green
.btn-success {
  &::first-letter {
    color: @yellow;
  }
  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.btn-info {
  &::first-letter {
    color: @yellow;
  }
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.btn-warning {
  &::first-letter {
    color: @yellow;
  }
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  &::first-letter {
    color: @yellow;
  }
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  //.button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @border-radius-large);
  padding: @baseLineHeight @baseLineWidth;
}
.btn-sm,.btn-xs {
  .box-shadow(none);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top:  @baseLineHeight;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
