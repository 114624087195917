//
// Code (inline and block)
// --------------------------------------------------


// Inline and block code styles
code,
kbd,
pre,
samp {
  font-family: @font-family-monospace;
}

// Inline code
code {
  padding: 0;
  font-size: @font-size-base;
  color: @code-color;
  background-color: @code-bg;
}

// User input typically entered via keyboard
kbd {
  padding: 0;
  font-size: @font-size-base;
  color: @kbd-color;
  background-color: @kbd-bg;

  kbd {
    padding: 0;
    font-size: @font-size-base;
    font-weight: normal;
  }
}

// Blocks of code
pre {
  display: block;
  padding: ((@line-height-computed - 1) / 2);
  margin: 0 0 (@line-height-computed / 2);
  font-size: @font-size-base;
  line-height: @line-height-base;
  word-break: break-all;
  word-wrap: break-word;
  color: @pre-color;
  background-color: @pre-bg;
  border: @borderWidth solid @pre-border-color;

  // Account for some code outputs that place code tags in pre tags
  code {
    padding: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
  }
}

// Enable scrollable blocks of code
.pre-scrollable {
  max-height: @pre-scrollable-max-height;
  overflow-y: scroll;
}
