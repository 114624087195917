#wrap386 {
  z-index: 9999999;
  background: @blueDark;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
#bar386 {
  color: @white;
  font-weight: bold;
  float: right;
  background: @blueDark;
  height: @baseLineHeight;
  margin-top: -@baseLineHeight;
  width: 100%;
}
#cursor386 {
  z-index: 9999999;
  color: @white;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  right: 0;
}
