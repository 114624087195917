//
// Popovers
// --------------------------------------------------


.popover {
  color: @black;
  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindex-popover;
  display: none;
  max-width: @popover-max-width;
  padding: 0px;
  // Reset font and text properties given new insertion method
  font-family: @font-family-base;
  font-size: @font-size-base;
  line-height: @line-height-base;
  text-align: left;
  background-color: @popover-bg;
  background-clip: padding-box;

  // Overrides for proper insertion
  white-space: normal;

  // Offset the popover to account for the popover arrow
  &.top     { margin-top: -@popover-arrow-width; }
  &.right   { margin-left: @popover-arrow-width; }
  &.bottom  { margin-top: @popover-arrow-width; }
  &.left    { margin-left: -@popover-arrow-width; }
}

.popover-title {
  margin: 0; // reset heading margin
  padding: 0 @baseWidth;
  color: @white;
  background-color: @popover-title-bg;
}

.popover-content {
  padding: 0 @baseWidth;
}

// Arrows
//
// .arrow is outer, .arrow:after is inner

.popover > .arrow {
  display: none;
}

